import { useState, useEffect } from "react";
import ImageOne from "../../assets/images/5913-removebg-preview.png";
import ImageTwo from "../../assets/images/65Z_2201.w012.n001.25B.p12.25-removebg-preview.png";
import ImageThree from "../../assets/images/environment-data-analytics-abstract-concept.png";
import ImageFour from "../../assets/images/food-delivery-abstract-concept.png";
import ImageFive from "../../assets/images/business-woman-business-man-with-diagram-document-puzzle.png";

import { PrimaryButton } from "../Buttons";
import { useTranslation } from "react-i18next";
import { FlyInComponent } from "../../hook/ScrollEffect";

export default function HomeCover() {
  const { t } = useTranslation();

  const images = [
    { src: ImageOne, title: t("self-service-kiosk") },
    { src: ImageTwo, title: t("self-checkout-pos") },
    { src: ImageThree, title: t("order-management") },
    { src: ImageFour, title: t("digital-ordering-system") },
    { src: ImageFive, title: t("accounting-integration") },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [images.length]);

  return (
    <div className="flex flex-col-reverse md:flex-row justify-center content-end mt-10">
      <div className="flex flex-col text-center md:text-start content-end justify-center md:flex-1">
        <FlyInComponent direction="x" index={0}>
          <div className="text-3xl md:w-3/4 lg:w-1/2 mx-auto">
            <p className="my-10">{t("cover-content")} </p>
            <div>
              <PrimaryButton label={t("book-demo")}  onClick={() =>
                document
                  .getElementById("contact")
                  .scrollIntoView({ behavior: "smooth" })
              }/>
            </div>
          </div>
        </FlyInComponent>
      </div>
        <div className="bg-gradient-primary border-8 border-darkGreen rounded-tl-full py-6 lg:py-24 rounded-tr-full rounded-bl-full md:flex-1">
        <FlyInComponent direction="x" index={1}>

          <div className="relative w-64 h-64 lg:w-80 lg:h-80 overflow-hidden mx-auto flex justify-center items-center">
            {images.map((image, index) => (
              <img
                key={index}
                src={image.src}
                alt={`slide-${index}`}
                className={`absolute object-cover transition-transform duration-700 ease-in-out ${
                  index === activeIndex
                    ? "translate-x-0 opacity-100"
                    : "translate-x-full opacity-0"
                }`}
              />
            ))}
          </div>
          {/* Title sliding in/out animation */}
          <div className="relative h-8">
            {images.map((image, index) => (
              <p
                key={index}
                className={`text-2xl uppercase absolute text-center w-full font-semibold transition-transform duration-700 ease-in-out ${
                  index === activeIndex
                    ? "translate-x-0 opacity-100"
                    : "translate-x-full opacity-0"
                }`}
              >
                {image.title}
              </p>
            ))}
          </div>
          </FlyInComponent>

        </div>
    </div>
  );
}
