import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importer oversettelsesfiler
import enTranslation from './locales/en/translation.json';
import noTranslation from './locales/no/translation.json';

// Hent lagret språk fra localStorage, hvis det finnes
const savedLanguage = localStorage.getItem('language') || 'en';

// Konfigurer i18next
i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enTranslation,
            },
            no: {
                translation: noTranslation,
            },
        },
        lng: savedLanguage, // Sett standard språk til lagret språk
        fallbackLng: 'en', // Fallback språk
        interpolation: {
            escapeValue: false, // React håndterer XSS
        },
    });

export default i18n;