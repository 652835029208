import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import emailjs from "emailjs-com";
import { PrimaryButton } from "../Buttons";
import ReCAPTCHA from "react-google-recaptcha"; // Added import
import { FlyInComponent } from "../../hook/ScrollEffect";
import { useTranslation } from "react-i18next";

const schema = yup
  .object({
    fullName: yup
      .string()
      .min(3, "Your full name should be at least 3 characters.")
      .required("Please enter your first name."),
    subject: yup
      .string()
      .min(3, "Subject should be at least 3 characters.")
      .required("Please enter subject"),
    email: yup
      .string()
      .email("Please enter a valid email address.")
      .required("Please enter your email."),
    body: yup
      .string()
      .min(3, "Your message should be at least 3 characters.")
      .required("Please enter your message."),
    phone: yup
      .string()
      .nullable() // Phone number is optional
      .matches(
        /^[0-9]{7,15}$/,
        "Please enter a valid phone number with 7 to 15 digits."
      ), // Validation for phone numbers
  })
  .required();

export function ContactForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null); // Added state for reCAPTCHA token
  const { t } = useTranslation();

  function onSubmit(data) {
    if (!recaptchaToken) {
      // Check for reCAPTCHA token
      window.alert("Please click to verify reCAPTCHA!");
      return;
    }

    const templateParams = {
      ...data,
      "g-recaptcha-response": recaptchaToken, // Include reCAPTCHA token
    };
    emailjs
      .send(
        "service_bzark1h",
        "template_xlsaq0l",
        templateParams,
        "XHRxxVuzePZubZk48"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setSubmitSuccess(true);
          reset();
          setRecaptchaToken(null); // Reset reCAPTCHA token on success
        },
        (error) => {
          console.log("FAILED...", error);
          window.alert("ERROR! Could not send message. Please try again!");
          reset();
          setRecaptchaToken(null); // Reset reCAPTCHA token on failure
        }
      );
  }

  useEffect(() => {
    if (submitSuccess) {
      window.alert("Your message was sent successfully!");
      setSubmitSuccess(false);
    }
  }, [submitSuccess]);

  return (
    <div className="my-5 p-3">
      <FlyInComponent direction="y">
        <h1 className="uppercase text-3xl text-primary text-center font-bold mt-36 mb-18">
          {t("contact-us")}
        </h1>
      </FlyInComponent>{" "}
      <FlyInComponent direction="y">
        <p className="text-center text-2xl py-16 lg:px-32">
          Get in touch today and we'll demonstrate how our innovative system can
          transform your business for the better.
        </p>
      </FlyInComponent>
      <FlyInComponent direction="y">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex-col md:w-2/4 content-center mx-auto"
        >
          <div className="mb-4">
            <label htmlFor="fullName" className=" text-lg">
              {t("full-name")}
            </label>
            <input
              type="text"
              id="fullName"
              {...register("fullName")}
              className={`mt-1 p-2 ${
                errors.fullName ? "error-border" : "border-gray-300"
              } rounded w-full`}
              placeholder="John Smith"
            />
            <p className="text-primary">{errors.fullName?.message}</p>
          </div>
          <div className="mb-4">
            <label htmlFor="subject" className="text-lg">
              {t("subject")}
            </label>
            <input
              type="text"
              id="subject"
              {...register("subject")}
              className={`mt-1 p-2  ${
                errors.subject ? "error-border" : "border-gray-300"
              } rounded w-full`}
              placeholder={t("subject")}
            />
            <p className="text-primary">{errors.subject?.message}</p>
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="text-lg">
              {t("email")}
            </label>
            <input
              type="email"
              id="email"
              {...register("email")}
              className={`mt-1 p-2 ${
                errors.email ? "error-border" : "border-gray-300"
              } rounded w-full`}
              placeholder="abc@mail.com"
            />
            <p className="text-primary">{errors.email?.message}</p>
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="text-lg">
              {t("phone")} (Optional)
            </label>
            <input
              type="text"
              id="phone"
              {...register("phone")}
              className={`mt-1 p-2 ${
                errors.phone ? "error-border" : "border-gray-300"
              } rounded w-full`}
              placeholder="+123456789"
            />
            <p className="text-primary">{errors.phone?.message}</p>
          </div>
          <div className="mb-4">
            <label htmlFor="body" className="text-lg">
              {t("message")}
            </label>
            <textarea
              id="body"
              {...register("body")}
              className={`mt-1 p-2  ${
                errors.body ? "error-border" : "border-gray-300"
              } rounded w-full`}
              placeholder={t("message-placeholder")}
            ></textarea>
            <p className="text-primary">{errors.body?.message}</p>
          </div>
          <div className="mb-4">
            <ReCAPTCHA
              sitekey="6LdB3EMqAAAAAJpVZwugJKujmgivlNg-xbXJPB0v" // Added reCAPTCHA component
              onChange={(token) => setRecaptchaToken(token)}
              onExpired={() => setRecaptchaToken(null)}
            />
          </div>
          <div className="mt-4 text-center">
            <PrimaryButton label={t("send")} />
          </div>
        </form>
      </FlyInComponent>{" "}
    </div>
  );
}
