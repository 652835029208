import { Layout } from "../Layout";
import { Routes, Route } from "react-router-dom";
import HomePageContent from "../HomePage";

function HomePage() {
  return <HomePageContent />;
}




function App() {
 
  return (
    <div className="bg-black ">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
