// Logo.jsx or Logo.js
import React from 'react';

const Logo = () => {
  return (
    <div className='font-bold uppercase text-xl'>
      <p className='text-primary text-2xl'>tech</p>
      <p className=''>intelligence</p>
    </div>
  );
};

export default Logo;
