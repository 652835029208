import { useEffect, useState } from 'react';
import { useRef } from "react";

function useIntersectionObserver(ref, options) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const currentRef = ref.current; // Store the current value of ref
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) { // Cleanup using the same stored reference
        observer.unobserve(currentRef);
      }
    };
  }, [options, ref]); // ref is intentionally excluded here

  return isVisible;
}
export const FlyInComponent = ({ children, threshold = 0.5, direction = "y", index = 0 }) => {
  const ref = useRef();
  const isVisible = useIntersectionObserver(ref, { threshold });

  // Define the transform based on the direction and index
  const translateClass =
    direction === "x"
      ? index % 2 === 0
        ? "-translate-x-20"
        : "translate-x-20"
      : "translate-y-20";

  return (
    <div
      ref={ref}
      className={`transition-transform duration-700 ease-out ${
        isVisible ? "transform translate-x-0 opacity-100" : `transform ${translateClass} opacity-0`
      }`}
    >
      {children}
    </div>
  );
};