import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Flag from "react-world-flags";

const LanguagesSelector = () => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("language") || "en"
  );

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    setSelectedLanguage(lng);
    setDropdownOpen(false); // Close the dropdown after selection
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className="flex items-center px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-500"
      >
        {selectedLanguage === "en" ? (
          <Flag code="GB" alt="English Flag" width="20" height="15" />
        ) : (
          <Flag code="NO" alt="Norwegian Flag" width="20" height="15" />
        )}
        <svg
          className={`ml-2 h-4 w-4 transition-transform transform ${
            dropdownOpen ? "rotate-180" : "rotate-0"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {dropdownOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <button
              onClick={() => changeLanguage("en")}
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
            >
              <Flag code="GB" alt="English Flag" width="20" className="mr-2" />
              English
            </button>
            <button
              onClick={() => changeLanguage("no")}
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
            >
              <Flag code="NO" alt="Norwegian Flag" width="20" className="mr-2" />
              Norsk
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguagesSelector;
