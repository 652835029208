import { ContactForm } from "../ContactForm";
import Clients from "./Clients";
import Features from "./Features";
import HomeCover from "./HomeCover";

export default function HomePageContent() {
  return (
    <div>
      <HomeCover />
      <div id="features">
        <Features />
      </div>
      <div id="clients">
        <Clients />
      </div>
      <div id="contact">
        <ContactForm />
      </div>
    
    </div>
  );
}
