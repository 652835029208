import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FeatureOne from "../../assets/images/featureOne.png";
import FeatureTwo from "../../assets/images/featureTwo.png";
import FeatureThree from "../../assets/images/featureThree.png";
import FeatureFour from "../../assets/images/featureFour.png";

import { FlyInComponent } from "../../hook/ScrollEffect";

export default function Features() {
  const { t } = useTranslation();
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const features = [
    {
      image: FeatureOne,
      title: t("feature-one"),
      content: t("content-feature-one"),
    },
    {
      image: FeatureTwo,
      title: t("feature-two"),
      content: t("content-feature-two"),
    },
    {
      image: FeatureThree,
      title: t("feature-three"),
      content: t("content-feature-three"),
    },
    {
      image: FeatureFour,
      title: t("feature-four"),
      content: t("content-feature-four"),
    },
  ];

  const handleImageClick = (image) => {
    setFullscreenImage(image);
  };

  const handleCloseFullscreen = () => {
    setFullscreenImage(null);
  };

  return (
    <div className="max-w-3xl my-28 md:mx-auto sm:text-center md:mb-12">
      {/* Heading with fly-in effect */}
      <FlyInComponent direction="y">
        <h1 className="uppercase text-3xl text-primary text-center font-bold mt-64 mb-32">
          {t("features")}
        </h1>
      </FlyInComponent>

      {/* Subheading with fly-in effect */}
      <FlyInComponent direction="y">
        <div className="py-16 mx-auto px-10">
          <h2 className="mb-6 text-2xl">
            <span className="relative inline-block text-primary">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10"
              >
                <defs>
                  <pattern
                    id="ea469ae8-e6ec-4aca-8875-fc402da4d16e"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#ea469ae8-e6ec-4aca-8875-fc402da4d16e)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative font-bold">Tech</span>
            </span>{" "}
            {t("features-content")}
          </h2>
        </div>
      </FlyInComponent>

      {/* Features grid */}
      <div className="grid gap-28 row-gap-12 lg:grid-cols-2">
        {features.map((feature, index) => (
          <FlyInComponent key={index} direction="x" index={index}>
            <div className="max-w-md mx-auto text-center group relative">
              <div
                className="relative w-80 h-80 mx-auto bg-gradient-secondary border-4 border-white rounded-tr-full rounded-tl-full rounded-br-full cursor-pointer transition-transform transform hover:scale-105 duration-500 ease-in-out"
                onClick={() => handleImageClick(feature.image)}
              >
                <img
                  src={feature.image}
                  alt={feature.title}
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
              <h3 className="my-6 text-xl uppercase text-primary">
                {feature.title}
              </h3>
              <p className="mb-3 text-lg text-start px-4">{feature.content}</p>
            </div>
          </FlyInComponent>
        ))}
      </div>

      {/* Fullscreen Image Overlay */}
      {fullscreenImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={handleCloseFullscreen}
        >
          <img
            src={fullscreenImage}
            alt="Fullscreen"
            className="max-w-screen max-h-screen object-contain"
            onClick={(e) => e.stopPropagation()} // Prevent click from closing overlay
          />
          <button
            onClick={handleCloseFullscreen}
            className="absolute top-4 right-4 text-white bg-black p-2 rounded-full hover:bg-gray-700"
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
}
